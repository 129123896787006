/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import { Formik, FormikProps } from "formik";
import { Button, Col, Form, Row, Spinner, Toast } from "react-bootstrap";
import { object, string } from "yup";
import { number } from "yup";
import valid from "card-validator";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useNavigate, useSearchParams } from "react-router-dom";
import { StateSelector } from "../../components/StateSelector";
import { PaySimpleCreditCardRequest } from "../../../functions/src/types/requests";

const schema = object({
    firstName: string().required("Required!"),
    lastName: string().required(),
    email: string().required().email(),
    address: string().required(),
    address2: string(),
    city: string().required(),
    state: string().required(),
    zip: string().required(),
    proposalAmount: string().required(),
    creditCard: object({ number: number().label("Card number").test("test-number", "Credit Card number is invalid", value => valid.number(value).isValid).required(), issuer: string().required(), 
        expirationDate: object({month: number().label("Expiration Month")
        .when('year', {
            is: (year: number) => year === new Date().getFullYear(),
            then: number().min(new Date().getMonth()+1)
        }).required()
        , year: number().required()})})
});

interface ICreditCardForm {
    firstName: string;
    lastName: string;
    email: string;
    address: string;
    address2?: string;
    city: string;
    state: string;
    zip: string;
    proposalAmount: string;
    creditCard: {
        number: string;
        issuer: string;
        expirationDate: {
            month: string;
            year: number;
        };
    };
}

export const CreditCard: React.VFC = () => {
    const analytics = getAnalytics();
    const [isProcessingPayment] = useState(false);
    const [showCreditCardErrorToast, setCreditCardErrorToast] = useState(false);
    const [searchParams] = useSearchParams();
    const merchantId = searchParams.get("merchantId") ?? "";

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    useEffect(() => {}, [isProcessingPayment]);
    const navigate = useNavigate();

    const routeChange = (): void => {
        const path = "/thank-you";
        navigate(path);
    };

    const monthNames = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10","11","12"];
    const date = new Date();
    const currentMonth = monthNames[date.getMonth()];
    const currentYear = date.getFullYear();

    const years: { value: number; label: number; }[] = [];

    for (let i = 0; i < 19; i+=1) {
        years.push({value: currentYear+i, label: currentYear+i});
    }

    const sanitizeCreditCardRequest = (creditCardForm: ICreditCardForm): PaySimpleCreditCardRequest => {
        const paySimpleCreditCardRequest: PaySimpleCreditCardRequest = {
            firstName: creditCardForm.firstName,
            lastName: creditCardForm.lastName,
            email: creditCardForm.email.trim(),
            merchantId,
            billingAddress: {
                streetAddress: creditCardForm.address,
                streetAddress2: creditCardForm.address2,
                city: creditCardForm.city,
                stateCode: creditCardForm.state,
                zipCode: creditCardForm.zip.trim()
            },
            proposalAmount: Number(creditCardForm.proposalAmount),
            creditCardType: creditCardForm.creditCard.issuer,
            creditCardNumber: creditCardForm.creditCard.number.trim(),
            expirationDate: `${creditCardForm.creditCard.expirationDate.month}/${creditCardForm.creditCard.expirationDate.year}`,
            zipCode: creditCardForm.zip.trim(),
        };
        return paySimpleCreditCardRequest;
    };

    return (
        <Formik
            validationSchema={schema}
            initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                address: "",
                address2: "",
                city: "",
                state: "AL",
                zip: "",
                proposalAmount: "",
                creditCard: { number: "", issuer: "Visa", expirationDate: { month: currentMonth, year: currentYear } }
            }}
            onSubmit={
                async (values: ICreditCardForm, actions) => {
                    actions.setSubmitting(true);
                    try {
                        const payWithCard = httpsCallable(getFunctions(), "payWithCreditCard");
                        const paySimpleCreditCardRequest: PaySimpleCreditCardRequest = sanitizeCreditCardRequest(values);
                        await payWithCard(paySimpleCreditCardRequest);
                        routeChange();
                    } catch (error) {
                        logEvent(analytics, "exception", {
                            description: "Failed to process credit card payment for customer",
                            requestBody: {...values, creditCard : {...values.creditCard, number: values.creditCard.number.substring(values.creditCard.number.length - 4)}}
                        });
                        setCreditCardErrorToast(true);
                    } finally {
                        actions.setSubmitting(false);
                    }
                }
            }
        >
            {(props: FormikProps<ICreditCardForm>) => {
                const { values, touched, errors, handleBlur, handleChange, isSubmitting, handleSubmit } = props
                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="firstName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control type="text" name="firstName" placeholder="First name on Account" value={values.firstName} isValid={touched.firstName && !errors.firstName} onChange={handleChange} onBlur={handleBlur} isInvalid={!!errors.firstName} />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} controlId="lastName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" name="lastName" placeholder="Last name on Account" value={values.lastName} isValid={touched.lastName && !errors.lastName} onChange={handleChange} onBlur={handleBlur} isInvalid={!!errors.lastName} />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="text" name="email" placeholder="Enter email" value={values.email} isValid={touched.email && !errors.email} onChange={handleChange} onBlur={handleBlur} isInvalid={!!errors.email}/>
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="address">
                            <Form.Label>Address</Form.Label>
                            <Form.Control type="text" name="address" placeholder="1234 Main St" value={values.address} isValid={touched.address && !errors.address} onChange={handleChange} onBlur={handleBlur} isInvalid={!!errors.address} />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="address2">
                            <Form.Label>Address 2</Form.Label>
                            <Form.Control type="text" name="address2" placeholder="Apartment, studio, or floor" value={values.address2} isValid={touched.address2 && !errors.address2} onChange={handleChange} onBlur={handleBlur} isInvalid={!!errors.address2}/>
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="city">
                                <Form.Label>City</Form.Label>
                                <Form.Control type="text" name="city" value={values.city} isValid={touched.city && !errors.city} onChange={handleChange} onBlur={handleBlur} isInvalid={!!errors.city}/>
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} controlId="state">
                                <Form.Label>State</Form.Label>
                                <Form.Select name="state" value={values.state} isValid={touched.state && !errors.state} onChange={handleChange} onBlur={handleBlur} isInvalid={!!errors.state}>
                                    <StateSelector />
                                </Form.Select>
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} controlId="zip">
                                <Form.Label>Zip</Form.Label>
                                <Form.Control type="text" name="zip" value={values.zip} isValid={touched.zip && !errors.zip} onChange={handleChange} onBlur={handleBlur} isInvalid={!!errors.zip}/>
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Form.Group>
                            <Form.Label>Enter Payment</Form.Label>
                            <Form.Control type="text" name="proposalAmount" value={values.proposalAmount} isValid={touched.proposalAmount && !errors.proposalAmount} onChange={handleChange} onBlur={handleBlur} isInvalid={!!errors.proposalAmount}></Form.Control>
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>
                        <div>
                            <Form.Group className="mb-3">
                                <Form.Label>Credit Card Number</Form.Label>
                                <Form.Control type="text" name="creditCard.number" value={values.creditCard?.number} isValid={touched.creditCard?.number && !errors.creditCard?.number} onChange={handleChange} onBlur={handleBlur} isInvalid={!!errors.creditCard?.number}></Form.Control>
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Select name="creditCard.issuer" value={values.creditCard?.issuer} isValid={touched.creditCard?.issuer && !errors.creditCard?.issuer} onChange={handleChange} onBlur={handleBlur} isInvalid={!!errors.creditCard?.issuer} >
                                <option value={"Visa"}>Visa</option>
                                <option value={"Master"}>Mastercard</option>
                                <option value={"Amex"}>Amex</option>
                                <option value={"Discover"}>Discover</option>
                            </Form.Select>
                        </div>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="creditCard.expiration.month">
                                <Form.Select name="creditCard.expirationDate.month" value={values.creditCard.expirationDate?.month} isValid={touched.creditCard?.expirationDate?.month && !errors.creditCard?.expirationDate?.month} onChange={handleChange} onBlur={handleBlur} isInvalid={!!errors.creditCard?.expirationDate?.month}>
                                    <option value={"01"}>January</option>
                                    <option value={"02"}>February</option>
                                    <option value={"03"}>March</option>
                                    <option value={"04"}>April</option>
                                    <option value={"05"}>May</option>
                                    <option value={"06"}>June</option>
                                    <option value={"07"}>July</option>
                                    <option value={"08"}>August</option>
                                    <option value={"09"}>September</option>
                                    <option value={"10"}>October</option>
                                    <option value={"11"}>November</option>
                                    <option value={"12"}>December</option>
                                </Form.Select>
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} controlId="creditCard.expiration.year">
                                <Form.Select name="creditCard.expirationDate.year" value={values.creditCard.expirationDate?.year} isValid={touched.creditCard?.expirationDate?.year && !errors.creditCard?.expirationDate?.year} onChange={handleChange} onBlur={handleBlur} isInvalid={!!errors.creditCard?.expirationDate?.year}>
                                    {years.map(({value, label}) => <option key={value} value={value}>{label}</option>)}
                                </Form.Select>
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Toast onClose={() => setCreditCardErrorToast(false)} bg="danger" show={showCreditCardErrorToast} delay={10000} autohide>
                            <Toast.Header>
                                <strong className="me-auto">Groundwork Payments Error</strong>
                            </Toast.Header>
                            <Toast.Body>
                                Contact your service provider and consider paying with bank account or check. You have not been charged.
                            </Toast.Body>
                        </Toast>
                        <Button type="submit" disabled={isSubmitting}>
                            {isSubmitting &&
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />}
                            {isSubmitting ? "Processing Payment..." : "Submit Payment"}
                        </Button>
                    </Form>
                );
            }}
        </Formik>
    );
};