/* eslint-disable max-len */
import React from "react";
import { Button } from "react-bootstrap";
import { BsFillCheckSquareFill } from "react-icons/bs"
import { useNavigate } from "react-router-dom";
import "./ThankYou.scss";

export const ThankYou: React.VFC = () => {
    const navigate = useNavigate();

    const routeChange = (): void => {
        const path = "/";
        navigate(path);
    };

    return (
        <div className="container">
            <div>Thank You for using Groundwork Payments!</div>
            <div><BsFillCheckSquareFill color="green" size="3em"/></div>
            <div><Button onClick={routeChange}>Make another payment?</Button></div>
        </div>
    );
};