/* eslint-disable max-len */
import React, { useState } from "react";
import { Button, Tab, Tabs } from "react-bootstrap";
import { CreditCard } from "./CreditCard";
import { ACH } from "./ACH";
import "./Payment.scss";
import { useNavigate } from "react-router-dom";

export const Payment: React.VFC = () => {
    
    const [, setCurrentTab] = useState<string | null>("creditcard");

    const navigate = useNavigate();

    const routeChange = (): void => {
        navigate("/thank-you");
    }

    return (
        <div>
            <Tabs id="payment-tab" variant="pills" className="mb-3 Payment" onSelect={(t) => setCurrentTab(t)}>
                <Tab eventKey="creditcard" title="Credit Card">
                    <CreditCard />
                </Tab>
                <Tab eventKey="ach" title="Bank Account">
                    <ACH />
                </Tab>
                <Tab eventKey="other" title="Other">
                    <Button onClick={routeChange}>Pay with a check</Button>
                </Tab>
            </Tabs>
        </div>
    );
};