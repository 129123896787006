/* eslint-disable max-len */
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAnalytics, logEvent } from "firebase/analytics";
import { Formik, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner, Toast } from "react-bootstrap";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useNavigate, useSearchParams } from "react-router-dom";
import { bool, object, string } from "yup";
import { PaySimpleACHRequest } from "../../../functions/src/types/requests";
import { StateSelector } from "../../components/StateSelector";
import "./ACH.scss";

const schema = object({
    firstName: string().required("Required!"),
    lastName: string().required(),
    email: string().required().email(),
    address: string().required(),
    address2: string(),
    city: string().required(),
    state: string().required(),
    zip: string().required(),
    proposalAmount: string().required(),
    ach: object({ bankName: string().required(), routingNumber: string().label("Routing Number").min(9).required(), accountNumber: string().label("Account Number").min(6).max(17).required(), isCheckingAccount: bool().required() })
});

interface IACHForm {
    firstName: string;
    lastName: string;
    email: string;
    address: string;
    address2?: string;
    city: string;
    state: string;
    zip: string;
    proposalAmount: string;
    ach: {
        bankName: string;
        routingNumber: string;
        accountNumber: string;
        isCheckingAccount: boolean;
    };
}

export const ACH: React.VFC = () => {
    const [searchParams] = useSearchParams();
    const merchantId = searchParams.get("merchantId") ?? "";
    const [showACHErrorToast, setACHErrorToast] = useState(false);
    const analytics = getAnalytics();

    const [isProcessingPayment] = useState(false);
    const navigate = useNavigate();
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    useEffect(() => {}, [isProcessingPayment]);

    const routeChange = (): void => {
        navigate("/thank-you");
    }

    const sanitizeACHRequest = (achForm: IACHForm): PaySimpleACHRequest => {
        const paySimpleACHRequest: PaySimpleACHRequest = {
            firstName: achForm.firstName,
            lastName: achForm.lastName,
            email: achForm.email.trim(),
            merchantId,
            billingAddress: {
                streetAddress: achForm.address,
                streetAddress2: achForm.address2,
                city: achForm.city,
                stateCode: achForm.state,
                zipCode: achForm.zip.trim(),
            },
            proposalAmount: Number(achForm.proposalAmount),
            bankName: achForm.ach.bankName,
            routingNumber: achForm.ach.routingNumber.trim(),
            accountNumber: achForm.ach.accountNumber.trim(),
            isCheckingAccount: achForm.ach.isCheckingAccount 
        };
        return paySimpleACHRequest;
    };

    return (
        <Formik
            validationSchema={schema}
            initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                address: "",
                address2: "",
                city: "",
                state: "AL",
                zip: "",
                proposalAmount: "",
                ach: {bankName: "", routingNumber: "", accountNumber: "", isCheckingAccount: true}
            }}
            onSubmit={
                async (values: IACHForm, actions) => {
                    actions.setSubmitting(true);
                    try {
                        const functions = getFunctions();
                        const payWithACH = httpsCallable(functions, "payWithACH");
                        // Need to build a PaySimpleACHRequest
                        const paySimpleACHRequest = sanitizeACHRequest(values);
                        await payWithACH(paySimpleACHRequest);
                        routeChange();
                    } catch (error) {
                        logEvent(analytics, "exception", {
                            description: "Failed to process ACH payment for customer",
                            requestBody: {...values, ach: {...values.ach, routingNumber: values.ach.routingNumber.substring(values.ach.routingNumber.length - 4), accountNumber: values.ach.accountNumber.substring(values.ach.accountNumber.length - 4)}}
                        });
                        setACHErrorToast(true);
                    } finally {
                        actions.setSubmitting(false);
                    }
                }
            }
            > 
            {(props: FormikProps<IACHForm>) => {
                const { values, touched, errors, handleBlur, handleChange, isSubmitting, handleSubmit } = props
            return(
            <Form noValidate onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="firstName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="text" name="firstName" placeholder="First name on Account" value={values.firstName} isValid={touched.firstName && !errors.firstName} onChange={handleChange} onBlur={handleBlur} isInvalid={!!errors.firstName} />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} controlId="lastName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="text" name="lastName" placeholder="Last name on Account" value={values.lastName} isValid={touched.lastName && !errors.lastName} onChange={handleChange} onBlur={handleBlur} isInvalid={!!errors.lastName} />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Form.Group className="mb-3" controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="text" name="email" placeholder="Enter email" value={values.email} isValid={touched.email && !errors.email} onChange={handleChange} onBlur={handleBlur} isInvalid={!!errors.email}/>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="address">
                    <Form.Label>Address</Form.Label>
                    <Form.Control type="text" name="address" placeholder="1234 Main St" value={values.address} isValid={touched.address && !errors.address} onChange={handleChange} onBlur={handleBlur} isInvalid={!!errors.address} />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="address2">
                    <Form.Label>Address 2</Form.Label>
                    <Form.Control type="text" name="address2" placeholder="Apartment, studio, or floor" value={values.address2} isValid={touched.address2 && !errors.address2} onChange={handleChange} onBlur={handleBlur} isInvalid={!!errors.address2}/>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="city">
                        <Form.Label>City</Form.Label>
                        <Form.Control type="text" name="city" value={values.city} isValid={touched.city && !errors.city} onChange={handleChange} onBlur={handleBlur} isInvalid={!!errors.city}/>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} controlId="state">
                        <Form.Label>State</Form.Label>
                        <Form.Select name="state" value={values.state} isValid={touched.state && !errors.state} onChange={handleChange} onBlur={handleBlur} isInvalid={!!errors.state}>
                            <StateSelector />
                        </Form.Select>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} controlId="zip">
                        <Form.Label>Zip</Form.Label>
                        <Form.Control type="text" name="zip" value={values.zip} isValid={touched.zip && !errors.zip} onChange={handleChange} onBlur={handleBlur} isInvalid={!!errors.zip}/>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Form.Group>
                    <Form.Label>Enter Payment</Form.Label>
                    <Form.Control type="text" name="proposalAmount" value={values.proposalAmount} isValid={touched.proposalAmount && !errors.proposalAmount} onChange={handleChange} onBlur={handleBlur} isInvalid={!!errors.proposalAmount} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Bank Name</Form.Label>
                    <Form.Control type="text" name="ach.bankName" placeholder="Bank Name" value={values.ach?.bankName} isValid={touched.ach?.bankName && !errors.ach?.bankName} onChange={handleChange} onBlur={handleBlur} isInvalid={!!errors.ach?.bankName}/>
                    <Form.Control.Feedback>Looks Good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Routing Number</Form.Label>
                    <Form.Control type="text" name="ach.routingNumber" placeholder="Routing Number" value={values.ach?.routingNumber} isValid={touched.ach?.routingNumber && !errors.ach?.routingNumber} onChange={handleChange} onBlur={handleBlur} isInvalid={!!errors.ach?.routingNumber}/>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Account Number</Form.Label>
                    <Form.Control type="text" name="ach.accountNumber" placeholder="Account Number" value={values.ach?.accountNumber} isValid={touched.ach?.accountNumber && !errors.ach?.routingNumber} onChange={handleChange} onBlur={handleBlur} isInvalid={!!errors.ach?.accountNumber}/>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3 ACH">
                    <Form.Check name="ach.isCheckingAccount" label="Is this a checking account?" ></Form.Check>
                </Form.Group>
                <Toast onClose={() => setACHErrorToast(false)} bg="danger" show={showACHErrorToast} delay={10000} autohide>
                            <Toast.Header>
                                <strong className="me-auto">Groundwork Payments Error</strong>
                            </Toast.Header>
                            <Toast.Body>
                                Contact your service provider and consider paying with a credit card or check. You have not been charged.
                            </Toast.Body>
                </Toast>
                <Button type="submit" disabled={isSubmitting}>
                    {isSubmitting &&
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />}
                    {isSubmitting ? "Processing Payment..." : "Submit Payment"}
                </Button>
            </Form>
            );
        }}
        </Formik>
    );
};